const urlBase = 'https://bildermangel.de';

export default class ImagePreview {
  constructor($element, options = {}) {
    this.$element = $element;
    this.options = {
      classes: {
        previewImage: 'image-preview img',
        previewWidth: 'image-preview__width',
        previewHeight: 'image-preview__height',
        previewBGColor: 'image-preview__bg',
        previewFGColor: 'image-preview__fg',
        previewFormat: 'image-preview__fmt',
        generateButton: 'image-preview__form',
      },
      ...options,
    };

    this.init();
  }

  init() {
    this.$image = this.$element.querySelector(`.${this.options.classes.previewImage}`);
  }

  update(width, height, bgcolor = '000000', fgcolor = '02B040', text = '', format = 'webp') {
    const $width = this.$element.querySelector(`.${this.options.classes.previewWidth}`);
    const $height = this.$element.querySelector(`.${this.options.classes.previewHeight}`);
    const $bgColor = this.$element.querySelector(`.${this.options.classes.previewBGColor}`);
    const $fgColor = this.$element.querySelector(`.${this.options.classes.previewFGColor}`);
    const $format = this.$element.querySelector(`.${this.options.classes.previewFormat}`);

    if ($width) $width.innerText = width;
    if ($height) $height.innerText = height;
    if ($bgColor) $bgColor.innerText = `#${bgcolor}`;
    if ($fgColor) $fgColor.innerText = `#${fgcolor}`;
    if ($format) $format.innerText = format;

    if (this.$image) {
      this.$image.srcset = `${urlBase}/${width}x${height}/${bgcolor}/${fgcolor}.${format}?text=${encodeURI(text)}`;
    }
  }
}

export class PreviewForm {
  constructor($element, options = { }) {
    this.$element = $element;
    this.options = {
      imagePreview: $element.getAttribute('aria-controls'),
      timeout: parseInt($element.dataset.timeout || 750, 10),
      ...options,
    };

    this.init();
  }

  init() {
    const $imagePreview = document.querySelector(`#${this.options.imagePreview}`);
    const $submit = this.$element.querySelector('[type=submit]');

    if ($imagePreview) {
      this.previewImage = new ImagePreview($imagePreview);
    }

    const {
      width, height, bgcolor, fgcolor, text, format,
    } = this.$element.elements;

    this.$inputs = [
      width,
      height,
      bgcolor,
      fgcolor,
      text,
      format,
    ];

    this.$inputs.forEach($input => $input.addEventListener('input', this.inputChange.bind(this)));

    if ($submit) {
      this.$element.addEventListener('submit', (e) => {
        e.preventDefault();
      });
      $submit.addEventListener('click', () => this.inputChange.bind(this));
    }
  }

  timeout() {
    const values = {};
    this.$inputs.forEach(($input) => { values[$input.name] = $input.value.replace('#', ''); });

    if (this.previewImage) {
      const {
        width, height, bgcolor, fgcolor, text, format,
      } = values;
      this.previewImage.update(width, height, bgcolor, fgcolor, text, format);
    }
  }

  inputChange() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }

    this.timer = setTimeout(this.timeout.bind(this), this.options.timeout);
  }
}

window.previewForm = new PreviewForm(
  document.querySelector('.js-preview-form'),
);
