import Slider from 'javascripts/globals/slider';

document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('.js-slideshow').forEach(
    $slideshow => new Slider($slideshow, {
      sliderFrame: 'slideshow__slider-items',
      sliderItem: 'slideshow__slider-item',
    }),
  );
});
